import React from 'react'
import {AnimatePresence, motion} from 'framer-motion'
import Nav from '../components/Nav'
import WorkFeatured from '../components/WorkFeatured'
import WorkArchive from '../components/WorkArchive'
import '../main.css'
import {TextHighlight} from '../components/Text/styles'
import Footer from '../components/Footer'

function Work() {
  return (
    <>
      <div class="grid">
        <div class="max-wrapper">
          <header>
            <Nav />
          </header>
          <AnimatePresence>
            <motion.div
              initial={{y: 16, opacity: 0}}
              animate={{y: 0, opacity: 1}}
              exit={{y: -16, opacity: 0}}
              transition={{duration: 0.6}}
            >
              <section class="prelude">
                <div class="indent">
                  <TextHighlight>
                    For +7 years my work has been focused on digital design, working on projects at
                    big and small scale and for diverse companies and environments
                  </TextHighlight>
                </div>
              </section>
            </motion.div>
            <motion.div
              initial={{y: 16, opacity: 0}}
              animate={{y: 0, opacity: 1}}
              exit={{y: -16, opacity: 0}}
              transition={{delay: 0.15, duration: 0.6}}
            >
              <WorkFeatured />
              <WorkArchive />
            </motion.div>
            <Footer />
          </AnimatePresence>
        </div>
      </div>
    </>
  )
}

export default Work
