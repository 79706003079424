import React from 'react'
import {NavLink} from 'react-router-dom'
import {AnimatePresence, motion} from 'framer-motion'
import {
  TextBody,
  TextHighlight,
  TextSectionHeading,
  TextCaption,
} from '../../components/Text/styles'
import ProjectFooter from '../../components/ProjectFooter'

// IMPORT IMAGES
import kivuVisual from '../../assets/images/work/kivu/01.jpg'
import kivuColorPalettes from '../../assets/images/work/kivu/02.jpg'
import kivuColorPicker from '../../assets/images/work/kivu/03.jpg'
import kivuInstagram from '../../assets/images/work/kivu/04.jpg'
import kivuTwitter from '../../assets/images/work/kivu/05.jpg'
import kivuDashboard from '../../assets/images/work/kivu/06.jpg'

function Kivu() {
  return (
    <>
      <div class="grid autoHeight">
        <div class="max-wrapper">
          <header>
            <>
              <NavLink id="navItem" to="/work" className="body" activeClassName="active">
                ⮢
              </NavLink>
            </>
          </header>

          <AnimatePresence>
            <motion.div
              initial={{y: 16, opacity: 0}}
              animate={{y: 0, opacity: 1}}
              exit={{y: -16, opacity: 0}}
              transition={{duration: 0.8}}
            >
              <section class="content">
                <article class="projectIntro">
                  <div>
                    <TextSectionHeading>Kivu</TextSectionHeading>
                    <TextCaption>2018 — 2019</TextCaption>
                  </div>
                  <div>
                    <TextHighlight>
                      For over a year and a half, I supported the visual and product design
                      direction and collaborated on the discovery, design, and test of new features
                      and their implementation.
                    </TextHighlight>
                  </div>
                  <div class="projectSection">
                    <TextBody>
                      Kivu wants to simplify the life of analysts who need to deal with big amounts
                      of data every single day. Their mission is to make network analysis and
                      visualization a standard for big data analytics. As the lead designer, I had
                      to reimagine how Kivu's Dashboard could improve in showing data and how that
                      would be impacting both visually and functionally to make the product
                      recognizable and different from the main competitors. Our approach tried to
                      highlight the nature of the data and keep the user interface elements as
                      subtle as possible. While being part of the in-house product and front-end
                      team, we also developed the basics of Kivu's design language and component
                      library and continued to improve working processes together with the
                      developers and the product manager.
                    </TextBody>
                  </div>
                </article>

                <article class="projectBlock">
                  <div class="projectSection">
                    <TextHighlight>Dealing with design debt</TextHighlight>
                    <TextBody>
                      They already had some essential components (in Angular) that were used to
                      build the product, so the changes performed in their design language were
                      relatively easy to apply. I worked hand-in-hand with the front-end developers
                      to understand how to tackle those components and their limitations.
                      Additionally, I supported the creation of categorical and sequential color
                      palettes for data visualization.
                    </TextBody>
                  </div>
                  <div class="projectImages">
                    <figure>
                      <img src={kivuVisual} alt="" />
                      <figcaption></figcaption>
                    </figure>
                    <figure>
                      <img src={kivuColorPalettes} alt="" />
                      <figcaption></figcaption>
                    </figure>
                    <figure>
                      <img src={kivuColorPicker} alt="" />
                      <figcaption></figcaption>
                    </figure>
                  </div>
                </article>

                <article class="projectBlock">
                  <div class="projectSection">
                    <TextHighlight>The dashboard</TextHighlight>
                    <TextBody>
                      At the same time that we were trying to define the basic foundations of Kivu’s
                      language and its product, I was in close collaboration with the product
                      manager building the features that were needed for the MVP. Among other
                      things, I was involved in the integration of Twitter and Instagram data into
                      the dashboard.
                    </TextBody>
                  </div>
                  <div class="projectImages">
                    <figure>
                      <img src={kivuInstagram} alt="" />
                      <figcaption></figcaption>
                    </figure>
                    <figure>
                      <img src={kivuTwitter} alt="" />
                      <figcaption></figcaption>
                    </figure>
                    <figure>
                      <img src={kivuDashboard} alt="" />
                      <figcaption></figcaption>
                    </figure>
                  </div>
                </article>
              </section>
              <ProjectFooter />
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </>
  )
}

export default Kivu
