import React from 'react'
import ReactCompareImage from 'react-compare-image'

import beforeImg from '../../assets/images/work/docplanner/designing/designing-01.png'
import afterImg from '../../assets/images/work/docplanner/designing/designing-02.png'

import './styles.css'

function ImgCompareDoc() {
  const before = beforeImg
  const after = afterImg

  return (
    <>
      <div className="mainWrapper">
        <div className="contentWrapper">
          <ReactCompareImage leftImage={before} rightImage={after} />
        </div>
      </div>
    </>
  )
}

export default ImgCompareDoc
