import React from 'react'
import {NavLink} from 'react-router-dom'
import {AnimatePresence, motion} from 'framer-motion'
import {
  TextBody,
  TextHighlight,
  TextSectionHeading,
  TextCaption,
} from '../../components/Text/styles'
import ProjectFooter from '../../components/ProjectFooter'

// IMPORT IMAGES
import delinskiLogo from '../../assets/images/work/delinski/01.jpg'
import delinskiTypeColor from '../../assets/images/work/delinski/02.jpg'
import delinskiIcons from '../../assets/images/work/delinski/03.jpg'
import delinskiRestaurants from '../../assets/images/work/delinski/04.jpg'
import delinskiScreens from '../../assets/images/work/delinski/05.jpg'

function Delinski() {
  return (
    <>
      <div class="grid autoHeight">
        <div class="max-wrapper">
          <header>
            <>
              <NavLink id="navItem" to="/work" className="body" activeClassName="active">
                ⮢
              </NavLink>
            </>
          </header>

          <AnimatePresence>
            <motion.div
              initial={{y: 16, opacity: 0}}
              animate={{y: 0, opacity: 1}}
              exit={{y: -16, opacity: 0}}
              transition={{duration: 0.8}}
            >
              <section class="content">
                <article class="projectIntro">
                  <div>
                    <TextSectionHeading>Delinski</TextSectionHeading>
                    <TextCaption>2020</TextCaption>
                  </div>
                  <div>
                    <TextHighlight>
                      In a very short amount of time, I managed to refine some of the elements of
                      their visual identity and redesign the mobile app.
                    </TextHighlight>
                  </div>
                  <div class="projectSection">
                    <TextBody>
                      Delinski helped foodies from Germany and Austria to find the best restaurants
                      and book their tables with the benefit of a discount. The company was acquired
                      by TripAdvisor in June 2020. During my collaboration with them, I helped
                      establish some of the basics of their identity system. Elements like a
                      corporate color palette, standardized typography usage, or custom iconography
                      were some of the first things we put in place. As the digital product designer
                      for the mobile app, I also contributed to every step of that project, from the
                      functional definition of the redesign to the elaboration of the final
                      prototypes.
                    </TextBody>
                  </div>
                </article>

                <article class="projectBlock">
                  <div class="projectSection">
                    <TextHighlight>Visual Identity</TextHighlight>
                    <TextBody>
                      With the redesign of their mobile application in mind, we decided to clean up
                      a few of their visual elements. We kept the distinctive orange color they
                      already had and added an accent teal, custom typography, and iconography.
                    </TextBody>
                  </div>
                  <div class="projectImages">
                    <figure>
                      <img src={delinskiLogo} alt="" />
                      <figcaption></figcaption>
                    </figure>
                    <figure>
                      <img src={delinskiTypeColor} alt="" />
                      <figcaption></figcaption>
                    </figure>
                    <figure>
                      <img src={delinskiIcons} alt="" />
                      <figcaption></figcaption>
                    </figure>
                  </div>
                </article>
                <article class="projectBlock">
                  <div class="projectSection">
                    <TextHighlight>The App</TextHighlight>
                    <TextBody>
                      Once we got the very basics, we jumped into defining the mobile app. The idea
                      was to improve the flows that we already had on the web and offer the
                      possibility to search by amount of guests, date and time, and type of cuisine,
                      as they were considered the most essential search terms for the users. My role
                      here was to define those search paths, design the interface and support its
                      development.
                    </TextBody>
                  </div>
                  <div class="projectImages">
                    <figure>
                      <img src={delinskiRestaurants} alt="" />
                      <figcaption></figcaption>
                    </figure>
                    <figure>
                      <img src={delinskiScreens} alt="" />
                      <figcaption></figcaption>
                    </figure>
                  </div>
                </article>
              </section>
              <ProjectFooter />
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </>
  )
}

export default Delinski
