import React from 'react'
import {AnimatePresence, motion} from 'framer-motion'

import autodraw from '../../assets/images/fun/autodraw.png'
import mtn from '../../assets/images/fun/mtn.gif'
import xtype from '../../assets/images/fun/xtype.gif'
import rapha from '../../assets/images/fun/rapha.png'
import ayuso from '../../assets/images/fun/ayuso.png'
import texttodots from '../../assets/images/fun/texttodots.gif'
import at from '../../assets/images/fun/at.png'
import lifework from '../../assets/images/fun/lifework.mp4'
import uiMotion from '../../assets/images/fun/uimotion.png'

import {TextBody, TextCaption} from '../Text/styles'

function FunGallery() {
  return (
    <>
      <section class="layoutContainer">
        <AnimatePresence>
          <motion.div
            class="item twoThirds"
            initial={{y: 10, opacity: 0}}
            whileInView={{y: 0, opacity: 1}}
            viewport={{once: true}}
            transition={{duration: 0.6}}
          >
            <a target="_blank" rel="noopener noreferrer" href="https://auto-draw.vercel.app/">
              <figure class="layoutFigure">
                <img class="layoutImg" src={autodraw} alt="" />
                <figcaption class="caption">
                  <div class="title">
                    <TextBody>Autodraw</TextBody>
                  </div>
                  <div class="tags">
                    <TextCaption>p5js</TextCaption>
                    <TextCaption>drawing tool</TextCaption>
                  </div>
                  <div class="projectLink">
                    <div class="linkIconOut">
                      <TextBody>→</TextBody>
                    </div>
                    <div class="linkText">
                      <TextBody>Visit demo</TextBody>
                    </div>
                  </div>
                </figcaption>
              </figure>
            </a>
          </motion.div>

          <motion.div
            class="item oneThird"
            initial={{y: 10, opacity: 0}}
            whileInView={{y: 0, opacity: 1}}
            viewport={{once: true}}
            transition={{duration: 0.6}}
          >
            <a target="_blank" rel="noopener noreferrer" href="https://mtn.vercel.app/">
              <figure class="layoutFigure">
                <img class="layoutImg" src={mtn} alt="" />
                <figcaption class="caption">
                  <div class="title">
                    <TextBody>MTN</TextBody>
                  </div>
                  <div class="tags">
                    <TextCaption>p5js</TextCaption>
                    <TextCaption>drawing tool</TextCaption>
                  </div>
                  <div class="projectLink">
                    <div class="linkIconOut">
                      <TextBody>→</TextBody>
                    </div>
                    <div class="linkText">
                      <TextBody>Visit demo</TextBody>
                    </div>
                  </div>
                </figcaption>
              </figure>
            </a>
          </motion.div>

          <motion.div
            class="item threeThirds"
            initial={{y: 10, opacity: 0}}
            whileInView={{y: 0, opacity: 1}}
            viewport={{once: true}}
            transition={{duration: 0.6}}
          >
            <a target="_blank" rel="noopener noreferrer" href="https://ui-motion.vercel.app/">
              <figure class="layoutFigure">
                <img class="layoutImg" src={uiMotion} alt="" />
                <figcaption class="caption">
                  <div class="title">
                    <TextBody>uiMotion</TextBody>
                  </div>
                  <div class="tags">
                    <TextCaption>react</TextCaption>
                    <TextCaption>interaction</TextCaption>
                  </div>
                  <div class="projectLink">
                    <div class="linkIconOut">
                      <TextBody>→</TextBody>
                    </div>
                    <div class="linkText">
                      <TextBody>Visit demo</TextBody>
                    </div>
                  </div>
                </figcaption>
              </figure>
            </a>
          </motion.div>

          <motion.div
            class="item oneThird"
            initial={{y: 10, opacity: 0}}
            whileInView={{y: 0, opacity: 1}}
            viewport={{once: true}}
            transition={{duration: 0.6}}
          >
            <a target="_blank" rel="noopener noreferrer" href="https://ayuso.vercel.app">
              <figure class="layoutFigure">
                <img class="layoutImg" src={ayuso} alt="" />
                <figcaption class="caption">
                  <div class="title">
                    <TextBody>Image matrix</TextBody>
                  </div>
                  <div class="tags">
                    <TextCaption>p5js</TextCaption>
                  </div>
                  <div class="projectLink">
                    <div class="linkIconOut">
                      <TextBody>→</TextBody>
                    </div>
                    <div class="linkText">
                      <TextBody>Visit demo</TextBody>
                    </div>
                  </div>
                </figcaption>
              </figure>
            </a>
          </motion.div>

          <motion.div
            class="item twoThirds"
            initial={{y: 10, opacity: 0}}
            whileInView={{y: 0, opacity: 1}}
            viewport={{once: true}}
            transition={{duration: 0.6}}
          >
            <a target="_blank" rel="noopener noreferrer" href="https://x-type.vercel.app/">
              <figure class="layoutFigure">
                <img class="layoutImg" src={xtype} alt="" />
                <figcaption class="caption">
                  <div class="title">
                    <TextBody>X.type</TextBody>
                  </div>
                  <div class="tags">
                    <TextCaption>p5js</TextCaption>
                    <TextCaption>kinetic type</TextCaption>
                  </div>
                  <div class="projectLink">
                    <div class="linkIconOut">
                      <TextBody>→</TextBody>
                    </div>
                    <div class="linkText">
                      <TextBody>Visit demo</TextBody>
                    </div>
                  </div>
                </figcaption>
              </figure>
            </a>
          </motion.div>

          <motion.div
            class="item twoThirds"
            initial={{y: 10, opacity: 0}}
            whileInView={{y: 0, opacity: 1}}
            viewport={{once: true}}
            transition={{duration: 0.6}}
          >
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://atatatatatatatatatatat.vercel.app/"
            >
              <figure class="layoutFigure">
                <img class="layoutImg" src={at} alt="" />
                <figcaption class="caption">
                  <div class="title">
                    <TextBody>AT</TextBody>
                  </div>
                  <div class="tags">
                    <TextCaption>p5js</TextCaption>
                    <TextCaption>kinetic type</TextCaption>
                  </div>
                  <div class="projectLink">
                    <div class="linkIconOut">
                      <TextBody>→</TextBody>
                    </div>
                    <div class="linkText">
                      <TextBody>Visit demo</TextBody>
                    </div>
                  </div>
                </figcaption>
              </figure>
            </a>
          </motion.div>
          <motion.div
            class="item oneThird"
            initial={{y: 10, opacity: 0}}
            whileInView={{y: 0, opacity: 1}}
            viewport={{once: true}}
            transition={{duration: 0.6}}
          >
            <a target="_blank" rel="noopener noreferrer" href="https://life-work.vercel.app/">
              <figure class="layoutFigure">
                <video loop autoPlay muted playsInline>
                  <source src={lifework} type="video/mp4" />
                </video>
                <figcaption class="caption">
                  <div class="title">
                    <TextBody>Life-work balance</TextBody>
                  </div>
                  <div class="tags">
                    <TextCaption>javascript</TextCaption>
                  </div>
                  <div class="projectLink">
                    <div class="linkIconOut">
                      <TextBody>→</TextBody>
                    </div>
                    <div class="linkText">
                      <TextBody>Visit demo</TextBody>
                    </div>
                  </div>
                </figcaption>
              </figure>
            </a>
          </motion.div>

          <motion.div
            class="item oneThird"
            initial={{y: 10, opacity: 0}}
            whileInView={{y: 0, opacity: 1}}
            viewport={{once: true}}
            transition={{duration: 0.6}}
          >
            <a target="_blank" rel="noopener noreferrer" href="https://texttodots.vercel.app">
              <figure class="layoutFigure">
                <img class="layoutImg" src={texttodots} alt="" />
                <figcaption class="caption">
                  <div class="title">
                    <TextBody>Text to dots</TextBody>
                  </div>
                  <div class="tags">
                    <TextCaption>p5js</TextCaption>
                    <TextCaption>kinetic type</TextCaption>
                  </div>
                  <div class="projectLink">
                    <div class="linkIconOut">
                      <TextBody>→</TextBody>
                    </div>
                    <div class="linkText">
                      <TextBody>Visit demo</TextBody>
                    </div>
                  </div>
                </figcaption>
              </figure>
            </a>
          </motion.div>
          <motion.div
            class="item twoThirds"
            initial={{y: 10, opacity: 0}}
            whileInView={{y: 0, opacity: 1}}
            viewport={{once: true}}
            transition={{duration: 0.6}}
          >
            <a target="_blank" rel="noopener noreferrer" href="https://rapha.vercel.app/">
              <figure class="layoutFigure">
                <img class="layoutImg" src={rapha} alt="" />
                <figcaption class="caption">
                  <div class="title">
                    <TextBody>Rapha</TextBody>
                  </div>
                  <div class="tags">
                    <TextCaption>jQuery</TextCaption>
                  </div>
                  <div class="projectLink">
                    <div class="linkIconOut">
                      <TextBody>→</TextBody>
                    </div>
                    <div class="linkText">
                      <TextBody>Visit demo</TextBody>
                    </div>
                  </div>
                </figcaption>
              </figure>
            </a>
          </motion.div>
        </AnimatePresence>
      </section>
    </>
  )
}

export default FunGallery
