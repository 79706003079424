import React from 'react'
import {NavLink} from 'react-router-dom'
import {AnimatePresence, motion} from 'framer-motion'
import {
  TextBody,
  TextCaption,
  TextHighlight,
  TextSectionHeading,
} from '../../components/Text/styles'
import ProjectFooter from '../../components/ProjectFooter'

// IMPORT IMAGES
import tourradarLogo from '../../assets/images/work/tourradar/01.jpg'
import tourradarType from '../../assets/images/work/tourradar/02.jpg'
import tourradarMktGuide from '../../assets/images/work/tourradar/03.jpg'
import tourradarMkt from '../../assets/images/work/tourradar/04.jpg'
import tourradarPhone from '../../assets/images/work/tourradar/05.jpg'
import tourradarScreens from '../../assets/images/work/tourradar/06.jpg'
import tourradarIcons from '../../assets/images/work/tourradar/07.jpg'

function TourRadar() {
  return (
    <>
      <div class="grid autoHeight">
        <div class="max-wrapper">
          <header>
            <>
              <NavLink id="navItem" to="/work" className="body" activeClassName="active">
                ⮢
              </NavLink>
            </>
          </header>
          <AnimatePresence>
            <motion.div
              initial={{y: 16, opacity: 0}}
              animate={{y: 0, opacity: 1}}
              exit={{y: -16, opacity: 0}}
              transition={{duration: 0.8}}
            >
              <section class="content">
                <article class="projectIntro">
                  <div>
                    <TextSectionHeading>TourRadar</TextSectionHeading>
                    <TextCaption>2015 — 2018</TextCaption>
                  </div>
                  <div>
                    <TextHighlight>
                      As part of the in-house design team, I refreshed the visual identity,
                      generated guidelines, handbooks, and templates, and supported the product
                      teams with UI design.
                    </TextHighlight>
                  </div>
                  <div class="projectSection">
                    <TextBody>
                      TourRadar helps travelers find the adventure of a lifetime and they are
                      working on bringing the touring industry online. Their aesthetic focuses on
                      the discovery theme and all its visual elements try to evoke the joy and hope
                      that we have when experiencing something new. With a strong focus on the
                      imagery, we drove the attention to what really matters: the experience of
                      discovering the world. During my time there, I helped redesign TourRadar's
                      visual identity and collaborated on improving their marketplace. We
                      established a humble UI kit together with the front-end team and —with the
                      collaboration of marketing, sales, and business development teams— we
                      elaborated guidelines and handbooks to be later used by them.
                    </TextBody>
                  </div>
                </article>

                <article class="projectBlock">
                  <div class="projectSection">
                    <TextHighlight>Visual identity and iconography</TextHighlight>
                    <TextBody>
                      The main goal at the beginning was to bring up-to-date the obsolete visual
                      identity that they had. We focused on the basics, as well as on a logo
                      clean-up, and tried to extract the essential elements that we wanted to keep
                      from their color palette and several corporate typefaces. All these decisions
                      were documented in internal and external guidelines.
                    </TextBody>
                  </div>
                  <div class="projectImages">
                    <figure>
                      <img src={tourradarLogo} alt="" />
                      <figcaption></figcaption>
                    </figure>
                    <figure>
                      <img src={tourradarIcons} alt="" />
                      <figcaption></figcaption>
                    </figure>
                    <figure>
                      <img src={tourradarType} alt="" />
                      <figcaption></figcaption>
                    </figure>
                    <figure>
                      <img src={tourradarMktGuide} alt="" />
                      <figcaption></figcaption>
                    </figure>
                    <figure>
                      <img src={tourradarMkt} alt="" />
                      <figcaption></figcaption>
                    </figure>
                  </div>
                </article>
                <article class="projectBlock">
                  <div class="projectSection">
                    <TextHighlight>UI and product</TextHighlight>
                    <TextBody>
                      After the visual upgrade to their identity, I had the opportunity to jump into
                      a product role. I got to work with product managers and front-end developers
                      delivering features for the marketplace at an insane pace. Establishing the
                      foundations and essential components was always a side project, so my journey
                      there ended up with the concept design of the mobile app.
                    </TextBody>
                  </div>
                  <div class="projectImages">
                    <figure>
                      <img src={tourradarPhone} alt="" />
                      <figcaption></figcaption>
                    </figure>
                    <figure>
                      <img src={tourradarScreens} alt="" />
                      <figcaption></figcaption>
                    </figure>
                  </div>
                </article>
              </section>
              <ProjectFooter />
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </>
  )
}

export default TourRadar
