import React from 'react'
import {AnimatePresence, motion} from 'framer-motion'
import Nav from '../components/Nav'
import FunGallery from '../components/FunGallery'
import '../main.css'
import {TextHighlight} from '../components/Text/styles'
import Footer from '../components/Footer'

function Fun() {
  return (
    <>
      <div class="grid autoHeight">
        <div class="max-wrapper">
          <header>
            <Nav />
          </header>
          <AnimatePresence>
            <motion.div
              initial={{y: 16, opacity: 0}}
              animate={{y: 0, opacity: 1}}
              exit={{y: -16, opacity: 0}}
              transition={{duration: 0.6}}
            >
              <section class="prelude">
                <div class="indent">
                  <TextHighlight>
                    Some of my side projects and ideas, all of them helped me to get into coding.
                    Everything you see here can also be found in my{' '}
                    <a
                      class="out"
                      href="https://github.com/this-is-juan"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      GitHub repos
                    </a>
                  </TextHighlight>
                </div>
              </section>
              <FunGallery />
            </motion.div>
            <Footer />
          </AnimatePresence>
        </div>
      </div>
    </>
  )
}

export default Fun
