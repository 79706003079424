import React, {useState, useEffect} from 'react'
import {AnimatePresence, motion} from 'framer-motion'

import {TextHighlight, TextCaption, TextSectionHeading} from '../Text/styles'
import './styles.css'

function Footer() {
  const month = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const d = new Date()
  let monthName = month[d.getMonth()]

  const [clockState, setClockState] = useState()

  useEffect(() => {
    setInterval(() => {
      const date = new Date()
      setClockState(date.toLocaleTimeString())
    }, 1000)
  }, [])

  return (
    <>
      <footer class="mainFooter">
        <AnimatePresence>
          <div class="footerFlex">
            <div class="talk">
              <motion.div
                initial={{y: 10, opacity: 0}}
                whileInView={{y: 0, opacity: 1}}
                viewport={{once: true}}
                transition={{delay: 0.2, duration: 0.6}}
              >
                <TextSectionHeading>Let's talk</TextSectionHeading>
              </motion.div>
              <div class="icons">
                <motion.div
                  initial={{y: 10, opacity: 0}}
                  whileInView={{y: 0, opacity: 1}}
                  viewport={{once: true}}
                  transition={{delay: 0.2, duration: 0.6}}
                >
                  <svg
                    width="56"
                    height="32"
                    viewBox="0 0 56 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M46.8067 25.5772C51.5766 22.8515 54 19.3908 54 16C54 12.6092 51.5766 9.14845 46.8067 6.42278C42.0934 3.72943 35.4484 2 28 2C20.5516 2 13.9066 3.72943 9.19329 6.42278C4.42335 9.14845 2 12.6092 2 16C2 19.3908 4.42335 22.8515 9.19329 25.5772C13.9066 28.2706 20.5516 30 28 30C35.4484 30 42.0934 28.2706 46.8067 25.5772ZM28 32C43.464 32 56 24.8366 56 16C56 7.16344 43.464 0 28 0C12.536 0 0 7.16344 0 16C0 24.8366 12.536 32 28 32Z"
                      fill="var(--foreground)"
                    />
                  </svg>
                </motion.div>
                <motion.div
                  initial={{y: 10, opacity: 0}}
                  whileInView={{y: 0, opacity: 1}}
                  viewport={{once: true}}
                  transition={{delay: 0.4, duration: 0.6}}
                >
                  <svg
                    width="56"
                    height="32"
                    viewBox="0 0 56 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M47.7649 25C47.4568 25.1958 47.1374 25.3883 46.8067 25.5772C43.6365 27.3888 39.5924 28.7643 35.0177 29.4707C36.4927 28.3044 37.7636 26.7811 38.7499 25H47.7649ZM50.4067 23H39.6931C40.4183 21.1685 40.8677 19.1412 40.975 17H53.93C53.6414 19.0635 52.4612 21.1237 50.4067 23ZM37.5324 23C38.3377 21.2404 38.8531 19.2145 38.9731 17H29V23H37.5324ZM27 23V17H17.0269C17.1469 19.2145 17.6623 21.2404 18.4676 23H27ZM19.5805 25H27V29.9401C24.1322 29.5966 21.4454 27.7931 19.5805 25ZM16.3069 23C15.5817 21.1685 15.1323 19.1412 15.025 17H2.07001C2.35863 19.0635 3.53881 21.1237 5.59333 23H16.3069ZM8.23509 25H17.2501C18.2364 26.7811 19.5073 28.3044 20.9823 29.4707C16.4076 28.7643 12.3635 27.3888 9.19329 25.5772C8.8626 25.3883 8.54319 25.1958 8.23509 25ZM29 25H36.4195C34.5546 27.7931 31.8678 29.5966 29 29.9401V25ZM53.93 15C53.6414 12.9365 52.4612 10.8763 50.4067 9H39.6931C40.4183 10.8315 40.8677 12.8588 40.975 15H53.93ZM46.8067 6.42278C47.1374 6.61174 47.4568 6.80424 47.7649 7H38.7499C37.7636 5.2189 36.4927 3.69562 35.0177 2.52925C39.5924 3.23573 43.6365 4.61122 46.8067 6.42278ZM29 2.05988C31.8678 2.40341 34.5546 4.20686 36.4195 7H29V2.05988ZM29 15V9H37.5324C38.3377 10.7596 38.8531 12.7855 38.9731 15H29ZM27 15V9H18.4676C17.6623 10.7596 17.1469 12.7855 17.0269 15H27ZM27 2.05988V7H19.5805C21.4454 4.20686 24.1322 2.40341 27 2.05988ZM20.9823 2.52925C19.5073 3.69562 18.2364 5.2189 17.2501 7H8.23509C8.54319 6.80424 8.8626 6.61174 9.19329 6.42278C12.3635 4.61122 16.4076 3.23573 20.9823 2.52925ZM2.07001 15C2.35863 12.9365 3.53881 10.8763 5.59333 9H16.3069C15.5817 10.8315 15.1323 12.8588 15.025 15H2.07001ZM0 16C0 7.35472 11.9992 0.310908 27 0.0100139V0H28H29V0.0100139C44.0008 0.310908 56 7.35472 56 16C56 24.6453 44.0008 31.6891 29 31.99V32H28H27V31.99C11.9992 31.6891 0 24.6453 0 16Z"
                      fill="var(--foreground)"
                    />
                  </svg>
                </motion.div>
              </div>
            </div>

            <div class="contact">
              <div>
                <motion.div
                  initial={{y: 10, opacity: 0}}
                  whileInView={{y: 0, opacity: 1}}
                  viewport={{once: true}}
                  transition={{delay: 0.2, duration: 0.6}}
                >
                  <TextHighlight>
                    <a
                      class="out"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="mailto:j.sancho.arango@gmail.com"
                    >
                      Email
                    </a>
                  </TextHighlight>
                </motion.div>

                <motion.div
                  initial={{y: 10, opacity: 0}}
                  whileInView={{y: 0, opacity: 1}}
                  viewport={{once: true}}
                  transition={{delay: 0.4, duration: 0.6}}
                >
                  <TextHighlight>
                    <a
                      class="out"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.are.na/this-is-juan/channels"
                    >
                      Are.na
                    </a>
                  </TextHighlight>
                </motion.div>

                <motion.div
                  initial={{y: 10, opacity: 0}}
                  whileInView={{y: 0, opacity: 1}}
                  viewport={{once: true}}
                  transition={{delay: 0.6, duration: 0.6}}
                >
                  <TextHighlight>
                    <a
                      class="out"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://github.com/this-is-juan"
                    >
                      GitHub
                    </a>
                  </TextHighlight>
                </motion.div>

                <motion.div
                  initial={{y: 10, opacity: 0}}
                  whileInView={{y: 0, opacity: 1}}
                  viewport={{once: true}}
                  transition={{delay: 0.8, duration: 0.6}}
                >
                  <TextHighlight>
                    <a
                      class="out"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.read.cv/thisisjuan"
                    >
                      Read.cv
                    </a>
                  </TextHighlight>
                </motion.div>
              </div>
              <motion.div
                class="timestamp"
                initial={{y: 10, opacity: 0}}
                whileInView={{y: 0, opacity: 0.5}}
                viewport={{once: true}}
                transition={{delay: 0.8, duration: 0.6}}
              >
                <TextCaption>
                  {monthName} {new Date().getFullYear()}
                </TextCaption>
                <TextCaption>South of Spain</TextCaption>
                <TextCaption>{clockState}</TextCaption>
              </motion.div>
            </div>
          </div>
        </AnimatePresence>
      </footer>
    </>
  )
}

export default Footer
