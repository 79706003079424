import React from 'react'
import {Link} from 'react-router-dom'
// import docplannerVideo from '../../assets/images/work/docplannerFeat.mp4'
import docplannerFeatured from '../../assets/images/work/featured.png'
import './styles.css'

import {TextBody, TextSectionHeading, TextCaption} from '../Text/styles'

function WorkFeatured() {
  return (
    <>
      <section class="latest">
        <article class="title">
          <TextSectionHeading>Featured</TextSectionHeading>
          <TextCaption>(01)</TextCaption>
        </article>
        <article class="featured">
          <Link to="/work/docplanner">
            <figure class="featuredFigure">
              <img class="imgFeatured" src={docplannerFeatured} alt="" />
              {/* <video loop autoPlay muted playsInline>
                <source src={docplannerVideo} type="video/mp4" />
              </video> */}
              <figcaption class="caption">
                <div class="title">
                  <TextBody>Docplanner</TextBody>
                </div>
                <div class="tags">
                  <TextCaption>Design System</TextCaption>
                </div>
                <div class="projectLink">
                  <div class="linkIcon">
                    <TextBody>⮠</TextBody>
                  </div>
                  <div class="linkText">
                    <TextBody>See project</TextBody>
                  </div>
                </div>
              </figcaption>
            </figure>
          </Link>
        </article>
      </section>
    </>
  )
}

export default WorkFeatured
