import React from 'react'
import {NavLink} from 'react-router-dom'
import './styles.css'

function ProjectFooter() {
  return (
    <>
      <footer class="projectFooter">
        <NavLink id="navItem" to="/work" className="body" activeClassName="active">
          ⮢ WORK
        </NavLink>
      </footer>
    </>
  )
}

export default ProjectFooter
