import React from 'react'
import {Route, Routes} from 'react-router-dom'

import './main.css'

import Home from './pages/Home'
import Fun from './pages/Fun'
import Work from './pages/Work'
import Docplanner from './pages/work/Docplanner'
import Delinski from './pages/work/Delinski'
import Kivu from './pages/work/Kivu'
import TourRadar from './pages/work/TourRadar'
import ScrollToTop from './components/ScrollToTop'

function App() {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/fun" element={<Fun />} />
        <Route path="/work" element={<Work />} />
        <Route path="/work/docplanner" element={<Docplanner />} />
        <Route path="/work/delinski" element={<Delinski />} />
        <Route path="/work/kivu" element={<Kivu />} />
        <Route path="/work/tourradar" element={<TourRadar />} />
      </Routes>
    </>
  )
}

export default App
