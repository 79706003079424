import React from 'react'
import {Link} from 'react-router-dom'
import {AnimatePresence, motion} from 'framer-motion'
import archive01 from '../../assets/images/work/archiveDelinski.png'
import archive02 from '../../assets/images/work/archiveKivu.png'
import archive03 from '../../assets/images/work/archiveTourradar.png'
import './styles.css'

import {TextBody, TextCaption, TextSectionHeading} from '../Text/styles'

function WorkArchive() {
  return (
    <>
      <section class="archive">
        <AnimatePresence>
          <article>
            <motion.div
              class="title"
              initial={{y: 10, opacity: 0}}
              animate={{y: 0, opacity: 1}}
              exit={{y: -10, opacity: 0}}
              transition={{duration: 0.6}}
            >
              <TextSectionHeading>Archive</TextSectionHeading>
              <TextCaption>(03)</TextCaption>
            </motion.div>
          </article>
          <article class="listing layoutContainer">
            <motion.div
              class="item half"
              initial={{y: 10, opacity: 0}}
              whileInView={{y: 0, opacity: 1}}
              viewport={{once: true}}
              transition={{duration: 0.6}}
            >
              <Link to="/work/delinski">
                <figure class="layoutFigure">
                  <img class="layoutImg" src={archive01} alt="" />
                  <figcaption class="caption">
                    <div class="title">
                      <TextBody>Delinski</TextBody>
                    </div>
                    <div class="tags">
                      <TextCaption>Visual design</TextCaption>
                      <TextCaption>App design</TextCaption>
                    </div>
                    <div class="projectLink">
                      <div class="linkIcon">
                        <TextBody>⮠</TextBody>
                      </div>
                      <div class="linkText">
                        <TextBody>See project</TextBody>
                      </div>
                    </div>
                  </figcaption>
                </figure>
              </Link>
            </motion.div>

            <motion.div
              class="item half"
              initial={{y: 10, opacity: 0}}
              whileInView={{y: 0, opacity: 1}}
              viewport={{once: true}}
              transition={{duration: 0.6}}
            >
              <Link to="/work/kivu">
                <figure class="layoutFigure">
                  <img class="layoutImg" src={archive02} alt="" />
                  <figcaption class="caption">
                    <div class="title">
                      <TextBody>Kivu</TextBody>
                    </div>
                    <div class="tags">
                      <TextCaption>UX design</TextCaption>
                      <TextCaption>UI design</TextCaption>
                    </div>
                    <div class="projectLink">
                      <div class="linkIcon">
                        <TextBody>⮠</TextBody>
                      </div>
                      <div class="linkText">
                        <TextBody>See project</TextBody>
                      </div>
                    </div>
                  </figcaption>
                </figure>
              </Link>
            </motion.div>

            <motion.div
              class="item half"
              initial={{y: 10, opacity: 0}}
              whileInView={{y: 0, opacity: 1}}
              viewport={{once: true}}
              transition={{duration: 0.6}}
            >
              <Link to="/work/tourradar">
                <figure class="layoutFigure">
                  <img class="layoutImg" src={archive03} alt="" />
                  <figcaption class="caption">
                    <div class="title">
                      <TextBody>TourRadar</TextBody>
                    </div>
                    <div class="tags">
                      <TextCaption>Visual design</TextCaption>
                      <TextCaption>UI design</TextCaption>
                    </div>
                    <div class="projectLink">
                      <div class="linkIcon">
                        <TextBody>⮠</TextBody>
                      </div>
                      <div class="linkText">
                        <TextBody>See project</TextBody>
                      </div>
                    </div>
                  </figcaption>
                </figure>
              </Link>
            </motion.div>
          </article>
        </AnimatePresence>
      </section>
    </>
  )
}

export default WorkArchive
