import styled from 'styled-components'
import '../../main.css'

export const TextPageHeading = styled.h1`
  font-size: var(--font-size-pageHeading);
  line-height: var(--line-height-heading);
  text-transform: uppercase;

  @media only screen and (max-width: 640px) {
    font-size: var(--font-size-40);
  }
`

export const TextSectionHeading = styled.p`
  font-size: var(--font-size-sectionHeading);
  line-height: var(--line-height-heading);

  @media only screen and (max-width: 640px) {
    font-size: var(--font-size-24);
  }
`
export const TextHighlight = styled.p`
  font-size: var(--font-size-highlight);
  line-height: var(--line-height-body);

  @media only screen and (max-width: 640px) {
    font-size: var(--font-size-18);
  }
`

export const TextBody = styled.p`
  font-size: var(--font-size-body);
  line-height: var(--line-height-body);
  letter-spacing: 0.4px;
`

export const TextCaption = styled.p`
  font-size: var(--font-size-caption);
  letter-spacing: 0.8px;
  line-height: var(--line-height-body);
`
