import React from 'react'
import {NavLink} from 'react-router-dom'
import {AnimatePresence, motion} from 'framer-motion'
import {
  TextBody,
  TextCaption,
  TextHighlight,
  TextSectionHeading,
} from '../../components/Text/styles'
import ImgCompareDoc from '../../components/ImgCompareDoc'
import ProjectFooter from '../../components/ProjectFooter'

// IMPORT IMAGES
import spacing01 from '../../assets/images/work/docplanner/spacing/spacing-01.png'
import spacing02 from '../../assets/images/work/docplanner/spacing/spacing-02.png'

import color01 from '../../assets/images/work/docplanner/color/color-01.png'
import color02 from '../../assets/images/work/docplanner/color/color-02.png'
import color03 from '../../assets/images/work/docplanner/color/color-03.png'
import color04 from '../../assets/images/work/docplanner/color/color-04.png'
import color05 from '../../assets/images/work/docplanner/color/color-05.png'
import color06 from '../../assets/images/work/docplanner/color/color-06.png'
import color07 from '../../assets/images/work/docplanner/color/color-07.png'

import figma01 from '../../assets/images/work/docplanner/figma/figma-01.jpg'
import figma02 from '../../assets/images/work/docplanner/figma/figma-02.png'
import figma03 from '../../assets/images/work/docplanner/figma/figma-03.jpg'
import figma04 from '../../assets/images/work/docplanner/figma/figma-04.jpg'

import components01 from '../../assets/images/work/docplanner/components/components-01.png'

import documentation01 from '../../assets/images/work/docplanner/documentation/documentation-01.png'

function Docplanner() {
  return (
    <>
      <div class="grid autoHeight">
        <div class="max-wrapper">
          <header>
            <>
              <NavLink id="navItem" to="/work" className="body" activeClassName="active">
                ⮢
              </NavLink>
            </>
          </header>

          <AnimatePresence>
            <motion.div
              initial={{y: 16, opacity: 0}}
              animate={{y: 0, opacity: 1}}
              exit={{y: -16, opacity: 0}}
              transition={{duration: 0.8}}
            >
              <section class="content">
                <article class="projectIntro">
                  <div>
                    <TextSectionHeading>Docplanner</TextSectionHeading>
                    <TextCaption>2020 — Now</TextCaption>
                  </div>
                  <div>
                    <TextHighlight>
                      Working on design systems. More details coming soon, but if you want to know
                      more about my work here, do not heasitate to{' '}
                      <a
                        class="out"
                        href="https://www.linkedin.com/in/jusancho/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        get in touch
                      </a>
                    </TextHighlight>
                  </div>
                  <div class="projectSection">
                    <TextBody>
                      Docplanner’s mission is to make the healthcare experience more human. We work
                      on web and app environments designing products for all the profiles in the
                      healthcare industry including patients, doctors, and their assistants. My work
                      there has always revolved around user interfaces and design systems. The case
                      exposed here will go through Watson, the ultimate effort to provide a
                      consolidated method to build the SaaS platform for doctors and assistants,
                      from design to development.
                    </TextBody>
                  </div>
                </article>

                <article class="projectBlock">
                  <div class="projectSection">
                    <TextHighlight>The problem</TextHighlight>
                    <TextBody>
                      Our UI Kit did not provide custom design directives as we were relying on
                      third-party libraries. We had a lack of control over what could be done and
                      became dependent on the framework, we weren’t flexible enough to adapt to
                      changes that product designers required and we were creating a lot of legacy
                      and dependencies due to the custom solutions introduced by several product
                      teams.
                    </TextBody>
                  </div>
                  <div class="projectSection">
                    <TextHighlight>The solution: Watson</TextHighlight>
                    <TextBody>
                      Watson is not only a project but also a team that was officially put together
                      to be in charge of the design system. With the Docplanner Design Language as
                      its foundation, it provides working code, design tools, and resources to
                      assist the teams in their quest to design the product.
                    </TextBody>
                  </div>
                  <div class="projectSection">
                    <TextHighlight>Starting point</TextHighlight>
                    <TextBody>
                      The first phase of Watson included the work needed for setting up all our
                      design directives. We worked on the typographic scale, border radii and
                      shadows, spacing rules, and color palette at a core and semantic level. I was
                      in charge of the last two: spacing and color, and I will be showing below the
                      result of those and our first batch of components.
                    </TextBody>
                  </div>
                </article>

                <article class="projectBlock">
                  <div class="projectSection">
                    <TextHighlight>Spacing</TextHighlight>
                    <TextBody>
                      Space is all over any design, it’s used in CSS properties like padding or
                      margin, and also to position objects. Epitomizes the “I design this way, you
                      build that way” gap between design and dev. What we did here was to generate
                      the needed tokens and components that could allow us to consistently apply
                      spacing rules across disciplines.
                    </TextBody>
                  </div>
                  <div class="projectImages">
                    <figure>
                      <img src={spacing01} alt="" />
                      <figcaption>
                        <TextCaption>Semantic spacing tokens</TextCaption>
                      </figcaption>
                    </figure>
                    <figure>
                      <img src={spacing02} alt="" />
                      <figcaption>
                        <TextCaption>Different grouped actions and the use of spacing</TextCaption>
                      </figcaption>
                    </figure>
                  </div>
                </article>

                <article class="projectBlock">
                  <div class="projectSection">
                    <TextHighlight>Color</TextHighlight>
                    <TextBody>
                      Maintaining consistent and engaging digital interfaces throughout the SaaS
                      platform for clinics, doctors and assistants demanded comprehensive guidance
                      around color usage. To get there, we started by building a programmatic color
                      palette based on the LCh color space (this space correlates nicely with how
                      the human eye perceives color). This gives us enough flexibility to provide
                      the needed color tokens for backgrounds, foregrounds, and borders and to
                      deliver design guidance on their usage.
                    </TextBody>
                  </div>
                  <div class="projectImages">
                    <figure>
                      <img src={color01} alt="" />
                      <figcaption>
                        <TextCaption>Core color tokens</TextCaption>
                      </figcaption>
                    </figure>

                    <div class="projectSection embedded">
                      <TextBody>
                        Our palette suggests meaning and usage for each of the colors in our system.
                        For example, from a semantic perspective, the color green is used to
                        reinforce a positive sentiment. The naming structure of our semantic palette
                        reflects the pieces of semantic information to help you understand the
                        purpose and usage of any color:{' '}
                        <code>--w-color-role-sentiment-emphasis-state</code>
                      </TextBody>
                    </div>
                    <figure>
                      <img src={color02} alt="" />
                      <figcaption>
                        <TextCaption>
                          Pairing foreground with their backgrounds counterparts
                        </TextCaption>
                      </figcaption>
                    </figure>

                    <figure>
                      <img src={color03} alt="" />
                      <figcaption>
                        <TextCaption>
                          Using the -inverted versions of our semantic colors
                        </TextCaption>
                      </figcaption>
                    </figure>
                    <figure>
                      <img src={color04} alt="" />
                      <figcaption>
                        <TextCaption>
                          Using primary, raised, and inset backgrounds to differentiate surfaces in
                          the interface
                        </TextCaption>
                      </figcaption>
                    </figure>
                    <figure>
                      <img src={color05} alt="" />
                      <figcaption>
                        <TextCaption>
                          Using neutral foregrounds to reinforce the hierarchy
                        </TextCaption>
                      </figcaption>
                    </figure>
                    <figure>
                      <img src={color06} alt="" />
                      <figcaption>
                        <TextCaption>Border color tokens applied to a table component</TextCaption>
                      </figcaption>
                    </figure>

                    <div class="projectSection embedded">
                      <TextBody>
                        Combining yellow is a bit trickier. Our semantic colors are built around the
                        concept of consistent luminance and contrast, but following them and
                        establishing a common rule does not work for yellow. This color by
                        definition needs to stay light to keep its meaning and that’s why we break
                        the rule by providing a one-off solution for the yellow shades.
                      </TextBody>
                    </div>

                    <figure>
                      <img src={color07} alt="" />
                      <figcaption>
                        <TextCaption>
                          An accent alert uses the foreground-accent-inverted token while a warning
                          alert must use foreground-warning to achieve a similar ratio. Its
                          background also employs a lighter shade to avoid browns
                        </TextCaption>
                      </figcaption>
                    </figure>
                  </div>
                </article>

                <article class="projectBlock">
                  <div class="projectSection">
                    <TextHighlight>Watson Web Kit in Figma</TextHighlight>
                    <TextBody>
                      After working on the foundations, we started tackling an important topic. How
                      could we expose these directives and future components to the design team? How
                      could we benefit from Figma? In order to solve this, we defined the usage of
                      properties and variants and their pros and cons for other designers and our
                      library maintenance. We started providing the very first spacing and layout
                      components and color and typography tokens.
                    </TextBody>
                  </div>
                  <div class="projectImages">
                    <figure>
                      <img src={figma01} alt="" />
                      <figcaption>
                        <TextCaption>
                          Spacing directives are provided as variables for designers to build their
                          spacers and stacks using auto-layout
                        </TextCaption>
                      </figcaption>
                    </figure>

                    <figure>
                      <img src={figma03} alt="" />
                      <figcaption>
                        <TextCaption>Color tokens in Figma are available as variables</TextCaption>
                      </figcaption>
                    </figure>

                    <figure>
                      <img src={figma04} alt="" />
                      <figcaption>
                        <TextCaption>
                          We also provide the Stark plugin installed by default to check all color
                          combinations
                        </TextCaption>
                      </figcaption>
                    </figure>

                    <figure>
                      <img src={figma02} alt="" />
                      <figcaption>
                        <TextCaption>
                          Card and modal are two examples of components using slots for greater
                          consistency and flexibility
                        </TextCaption>
                      </figcaption>
                    </figure>
                  </div>
                </article>

                <article class="projectBlock">
                  <div class="projectSection">
                    <TextHighlight>Components</TextHighlight>
                    <TextBody>
                      If we had to streamline what builds the SaaS platform we will end up with two
                      key elements: forms and tables. The second phase of Watson was dedicated to
                      form components and I had in my plate the button, icon, icon button, input,
                      native select, or textarea among others. The design process is consistently
                      applied across components, we first audit SaaS, then elaborate a proposal with
                      the aid of the developers and we write down the documentation.
                    </TextBody>
                  </div>
                  <div class="projectImages">
                    <figure>
                      <img src={components01} alt="" />
                      <figcaption>
                        <TextCaption>Overview of some components</TextCaption>
                      </figcaption>
                    </figure>
                  </div>
                </article>

                <article class="projectBlock">
                  <div class="projectSection">
                    <TextHighlight>Documentation</TextHighlight>
                    <TextBody>
                      The documentation is custom build by the design system team and contains all
                      the necessary information. From recurrent updates, to a guideline to get
                      started using the system for both developers and designers to a thoughtful
                      documentation of the foundations, components, best practices and templates our
                      users can consume.
                    </TextBody>
                  </div>
                  <div class="projectImages">
                    <figure>
                      <img src={documentation01} alt="" />
                      <figcaption>
                        <TextCaption>Watson documentation</TextCaption>
                      </figcaption>
                    </figure>
                  </div>
                </article>

                <article class="projectBlock">
                  <div class="projectSection">
                    <TextHighlight>Designing with Watson</TextHighlight>
                    <TextBody>
                      Below you can find an example of one of the screens we templated and
                      re-designed using the Watson Design System. More extensive and final examples
                      will be coming soon.
                    </TextBody>
                  </div>
                  <div class="projectImages">
                    <figure>
                      <ImgCompareDoc />
                      <figcaption>
                        <TextCaption>Table view re-designed using Watson</TextCaption>
                      </figcaption>
                    </figure>
                  </div>
                </article>
              </section>
              <ProjectFooter />
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </>
  )
}

export default Docplanner
